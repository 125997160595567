import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { createBonusProcessSelectOption, createReportTypeSelectOption, getReportTypes } from "../../../utils/commissionTable";

const CommissionTableFilter = ({
	reportType,
	setReportType,
	setBonusProcess,
	bonusProcesses,
	setCurrentPage
}) => {
	const { t } = useTranslation();
	const reportTypeId = reportType?.value || 0;

	const [reportTypeList, setReportTypeList] = useState([]);
	const [selectedReportType, setSelectedReportType] = useState(null);

	const [bonusProcessList, setBonusProcessList] = useState([]);
	const [selectedBonusProcess, setSelectedBonusProcess] = useState(null);

	useEffect(() => {
		// Skip if bonusProcesses data is not ready or if the bonusProcessList already
		// has the exact bonusProcess data.
		if (!bonusProcesses || areBonusProcessArraysMatching()) {
			return;
		}

		const selectList = [];
		bonusProcesses?.forEach((bonusProcess) => {
			selectList.push(createBonusProcessSelectOption(bonusProcess));
		});

		setBonusProcessList(selectList);
		changeSelectedBonusProcess(selectList[0]);
	}, [bonusProcesses, bonusProcessList]); // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		const reportTypes = getReportTypes();
		if (reportTypes.length > 0) {
			const selectList = [];
			reportTypes?.forEach((reportType) => {
				selectList.push(createReportTypeSelectOption(reportType));
			});

			setReportTypeList(selectList);
			changeSelectedReportType(selectList[0]);
		}
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	const areBonusProcessArraysMatching = () => {
		let isArrayMatch = true;

		// Only reload the bonusProcessList if there are actual changes in bonusProcesses.
		if (bonusProcessList && bonusProcesses.length === bonusProcessList.length) {
			for (let i = 0, length = bonusProcesses.length; i < length; i++) {
				if (bonusProcesses[i].processId !== bonusProcessList[i].value) {
					isArrayMatch = false;
					break;
				}
			}
		}
		else {
			isArrayMatch = false;
		}

		return isArrayMatch;
	};

	const changeSelectedReportType = (selectedOption) => {
		setSelectedReportType(selectedOption);
		setReportType(selectedOption);
		setCurrentPage(1);
	};

	const changeSelectedBonusProcess = (selectedOption) => {
		setSelectedBonusProcess(selectedOption);
		setBonusProcess(selectedOption);
		setCurrentPage(1);
	};

	return (
		<div className="filter_Section">
			<div className="row">
				<>
					<div key={"2"} className="col-md-4">
						<div className="right_search_div">
							<label className="la-control">{t("report")}</label>
							<Select
								options={reportTypeList}
								onChange={changeSelectedReportType}
								isMulti={false}
								value={selectedReportType}
							/>
						</div>
					</div>

					{reportTypeId !== 100 && reportTypeId !== 101 && (
						<div key={"3"} className="col-md-4">
							<div className="right_search_div">
								<label className="la-control">{t("bonus.period")}</label>
								<Select
									options={bonusProcessList}
									onChange={changeSelectedBonusProcess}
									isMulti={false}
									value={selectedBonusProcess}
								/>
							</div>
						</div>
					)}
				</>
			</div>
		</div>
	);
};

export default CommissionTableFilter;