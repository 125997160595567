import React, { useEffect, useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { ApiHook } from '../../../hooks/apiHook';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import CurrencyConverter from '../../../Currency/CurrencyConverter';

const EditOrderModal = ({ show, handleClose, details, partyId }) => {
  const [count, setCount] = useState(details?.quantity);
  const queryClient = useQueryClient();
  const currency = useSelector(
    (state) => state.user?.selectedCurrency
  );
  const conversionFactor = useSelector(
    (state) => state?.user?.conversionFactor
  );
  //--------------------------- API --------------------------------
  const deleteCartItem = ApiHook.CallDeleteGuestOrder();
  const createOrder = ApiHook.CallCreateGuestOrder();

  const handleIncrement = () => {
    const data = {
      partyId: partyId.value,
      guestId: details.guestId,
      productId: details.productId,
      quantity: 1
    };
    createOrder.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          setCount(count + 1);
          queryClient.invalidateQueries({ queryKey: ["party-details"] });
        } else {
          toast.error(res.description);
        }
      }
    })
  };


  const handleDecrement = () => {
    if (count === 1) {
      return handleDelete();
    } else {
      const data = {
        partyId: partyId.value,
        guestId: details.guestId,
        productId: details.productId,
        quantity: -1
      };
      createOrder.mutate(data, {
        onSuccess: (res) => {
          if (res.status) {
            setCount(count - 1);
            queryClient.invalidateQueries({ queryKey: ["party-details"] });
          } else {
            toast.error(res.description);
          }
        }
      })
    }
  };

  const handleDelete = () => {
    const data = {
      partyId: partyId.value,
      guestId: details.guestId,
      cartId: details.id
    }
    if (data.cartId) {
      deleteCartItem.mutate(data, {
        onSuccess: (res) => {
          if (res.status) {
            handleClose()
            toast.success(res.data)
            queryClient.invalidateQueries({ queryKey: ['party-details'] })
          }
        }
      })
    }

  };
  console.log(count, "count");


  useEffect(() => {
    setCount(details?.quantity);
  }, [details?.quantity])

  return (
    <Modal show={show} onHide={handleClose} centered size="xl" backdrop="static" keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Order</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered className="table-manage-invite-party-portal">
          <thead>
            <tr>
              <th>No</th>
              <th>Guest Name</th>
              <th>Count</th>
              <th>Price</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{details?.id}</td>
              <td>{details?.guest}</td>
              <td>
                <div className="d-flex gap-3 align-items-center">
                  <button className="btn add_btn_edit_modal" onClick={handleDecrement}>
                    <i className="fa-solid fa-minus"></i>
                  </button>
                  {count}
                  <button className="btn add_btn_edit_modal" onClick={handleIncrement}>
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
              </td>
              <td>{currency?.symbolLeft}{" "}{CurrencyConverter(details?.totalAmount, conversionFactor)}</td>
              <td>
                <button onClick={handleDelete} className="btn add_btn_delete_modal btn-outline-danger">
                  <i className="fa-solid fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditOrderModal;
