import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SubmitButton from "../Common/buttons/SubmitButton";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import Select from "react-select";
import { useQueryClient } from "@tanstack/react-query";

const PaymentDetailsTab = ({ payment }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const [paymentMethodType, setPaymentMethodType] = useState(payment.options?.find(option => option.id === payment.selectedPayout) ?? payment.options.find((option) => option.slug === "bank-transfer"));
  const [isEditModeEnabled, setIsEditModeEnabled] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (payment?.selectedPayout !== undefined) {
      setPaymentMethodType(payment.options?.find(option => option.id === payment.selectedPayout));
    }
  }, [payment]);
  const paymentMutation = ApiHook.CallPaymentDetails();
  const getStripeAccountId = ApiHook.CallGetStripeAccountId();

  const handleChange = (event) => {
    const { value } = event.target;
    setPaymentMethodType({
      ...paymentMethodType,
      fields: paymentMethodType.fields.map( field => {
        if (field.slug === event.target.name) {
          return {...field, value: value };
        }else{
          return field;
        }
      })
    });
    setError(""); // Clear any previous errors
  };

  const toggleEditMode = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
    setError(""); // Clear any previous errors
  };

  const changePaymentMethod = (item) => {
    setPaymentMethodType(item);
    setError(""); // Clear any previous errors
  };
  const linkSubmit = () => {
    setLoading(true);
    getStripeAccountId.mutateAsync(null, {
        onSuccess: (res) => {
            if (res.status) {
                setLoading(false);
                window.location.href = res?.data?.accountUrl;
            } else {
                setLoading(false);
                toast.error(t("errorOccuredPleaseTryAgain"));
            }
        },
        onError: (res) => {
            setLoading(false);
            toast.error(t("errorOccuredPleaseTryAgain"));
        },
    });
  };

  const onSubmit = () => {
    if (paymentMethodType.fields?.length > 0 && paymentMethodType.fields.find(field => !field.optional && !field.value)) {
      setError("this_field_is_required");
      return;
    }

    // TODO: Validate Tron Address
    // let tronAddress;
    // if (paymentMethodType.fields?.find(field => field.type === 'tron' && (!field.optional || field.value) && (tronAddress = field.value))) {
    //   validateTronMutation.mutate(tronAddress, {
    //     onSuccess: (res) => {
    //       if (res.code || res.message) {
    //         toast.error(t(res?.description));
    //         setError("Failed to validate USDT address!");
    //       }
    //     },
    //     onError: (err) => {
    //       setError("Failed to validate USDT address!");
    //     },
    //   });
    //
    //   if (error) return;
    // }

    // if ((!paymentValues || paymentValues.length === 0) && (paymentMethodType.slug === "stripe")) {
    //   setError("updateStripe");
    //   return;
    // }

    let data = {
      paymentMethod: paymentMethodType.id,
      fields: paymentMethodType.fields.map(field => { return { fieldId: field.id, value: field.value } }),
    };
    paymentMutation.mutate(data, {
      onSuccess: (res) => {
        if (res.status) {
          queryClient.invalidateQueries({ queryKey: ["profile"] });
          toast.success(t(res?.data));
          setIsEditModeEnabled(false);
        } else if (res.code) {
          toast.error(t(res?.description));
        } else if (res.message) {
          toast.error(t(res?.message));
        }
      },
      onError: (err) => {
        setError("Failed to update payment details. Please try again.");
      },
    });
  };
  
  return (
    <div id="fourthTab" className="tabcontent">
      <div className={`editSec ${isEditModeEnabled ? "disabled" : ""}`}>
        <span className={"btn btn-link"} onClick={toggleEditMode}>
          <i className="fa-solid fa-edit"></i>
        </span>
      </div>
      <h3>{t("paymentDetails")}</h3>
      <div className="tabcontent_form_section">
        {paymentMethodType?.slug === "stripe" && (
          <div className="mb-3 row tabBlockClass">
            <label className="col-sm-3 col-form-label labelWidthClass">
              {t("stripeAccount")}:
            </label>
            <div className="col-md-9 col-sm-12 col-12">
              <div style={{ display: "flex" }}>
                <input
                  name="stripeAccount"
                  type="text"
                  className="form-control"
                  disabled={!isEditModeEnabled && true}
                  onChange={handleChange}
                  value={paymentMethodType ?? {}}
                />
                <button
                  className="linkStrip_btn"
                  onClick={linkSubmit}
                  type="submit"
                >
                {" "}
                {loading ? (
                  <>
                      {"Linking to Stripe"}{" "}
                        <i
                          className="fa fa-refresh fa-spin"
                          style={{
                            marginRight: "5px",
                            Color:"transparent"
                          }}
                          />
                    </>
                ) : (
                  "Link to Stripe"
                )}
                </button>
              </div>
                {error && <p className="text-danger">{t(error)}</p>}
            </div>
          </div>
        )}
        { paymentMethodType?.fields && paymentMethodType?.fields.length > 0 && paymentMethodType?.fields.map((paymentValue) => {
          return (
            <div className="mb-3 row tabBlockClass" key={paymentValue.slug}>
              <label className="col-sm-3 col-form-label labelWidthClass">
                {t([paymentValue.slug, paymentValue.name])}:
              </label>
              <div className="col-md-9 col-sm-12 col-12">
                <input
                  name={paymentValue.slug}
                  type="text"
                  className="form-control"
                  value={paymentValue.value ?? ""}
                  disabled={!isEditModeEnabled}
                  onChange={handleChange}
                />
                {error && <p className="text-danger">{t(error)}</p>}
              </div>
            </div>
          )
        })}
        <div className="mb-3 row tabBlockClass">
          <label className="col-sm-3 col-form-label labelWidthClass">
            {t("paymentMethod")}:
          </label>
          <div className="col-md-9 col-sm-12 col-12">
            <Select
              value={paymentMethodType}
              options={payment?.options}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => `${option.id}`}
              isDisabled={!isEditModeEnabled}
              onChange={changePaymentMethod}
            />
          </div>
        </div>
        <div className={`paymenytLinkBtn ${isEditModeEnabled ? "disabled" : ""}`}>
          <SubmitButton
            id={"1"}
            isSubmitting={!isEditModeEnabled || paymentMethodType?.slug === "stripe"}
            click={onSubmit}
            text="update"
            className="btn"
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailsTab;