import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { TableSkeleton } from "./TableSkeleton";
import { useSelector } from "react-redux";
import CurrencyConverter from "../../../Currency/CurrencyConverter";
import { numberDisplay } from "../../../utils/numberDisplay";
import {getInvoiceNumber, getPeriodTypeLocaleKey} from "../../../utils/commissionTable";

const CommissionTableContent = ({
	data,
	reportType
}) => {
	const { t } = useTranslation();
	const reportTypeId = reportType?.value || 0;

	const userSelectedCurrency = useSelector(
		(state) => state.user?.selectedCurrency
	);
	const conversionFactor = useSelector(
		(state) => state?.user?.conversionFactor
	);

	const headers = getHeaders(reportTypeId);

	function getHeaders(reportTypeId) {
		let headers;
		if (reportTypeId === 1) {
			headers = [
				"bonus.payOnRepId",
				"bonus.payOnName",
				"bonus.orderId",
				// "bonus.sourceAmount",
				// "bonus.bonusPercent",
				"bonus.amount"
			];
		}
		else if (reportTypeId === 2) {
			headers = {
				headers1: [
					"bonus.previousCarryoverLV",
					"bonus.periodLV",
					"bonus.totalLV",
					"bonus.previousCarryoverRV",
					"bonus.periodRV",
					"bonus.totalRV"
				],
				headers2: [
					"bonus.rank",
					"bonus.bonusAmount",
					"bonus.cumulativeBonusAmount",
					"bonus.totalLV",
					"bonus.cycleAmountLeftLeg",
					"bonus.currentLV",
					"bonus.totalRV",
					"bonus.cycleAmountRightLeg",
					"bonus.currentRV"
				],
				headers3: [
					"bonus.rank",
					"bonus.currentLV",
					"bonus.flushedLV",
					"bonus.carryoverLV",
					"bonus.currentRV",
					"bonus.flushedRV",
					"bonus.carryoverRV"
				]
			};
		}
		else if (reportTypeId === 3) {
			headers = [
				"bonus.payOnRepId",
				"bonus.payOnName",
				"bonus.level",
				"bonus.compressedLevel",
				"bonus.sourceAmount",
				"bonus.bonusPercent",
				"bonus.amount"
			];
		}
		else if (reportTypeId === 100) {
			headers = [
				"bonus.period",
				"bonus.official",
				"bonus.rank",
				"bonus.totalAmount",
				"bonus.fastStartBonus",
				"bonus.residualBonus",
				"bonus.matchingBonus"
			];
		}
		else if (reportTypeId === 101) {
			headers = [
				"bonus.period",
				"bonus.rank",
				"bonus.active",
				"bonus.periodLV",
				"bonus.periodRV",
				"bonus.activeRepsLeft",
				"bonus.activeRepsRight"
			];
		}

		return headers;
	}

	function renderFastStartBonus() {
		return (
			<div className="table-container">
				<table className="striped" style={{ width: "100%" }}>
					{renderHeaders(headers)}

					{renderSkeletonOrEmpty(headers)}

					{data?.length > 0 && (
						<tbody>
						{data?.map((row, rowIndex) => (
							<tr key={rowIndex}>
								<td>{row.payOnRepId}</td>
								<td>{row.payOnName}</td>
								<td>{getInvoiceNumber(row.orderId)}</td>
								{/*<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.sourceAmount, conversionFactor, 0, false)}`}</td>*/}
								{/*<td>{`${row.bonusPercent} %`}</td>*/}
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.amount, conversionFactor, 0, false)}`}</td>
							</tr>
						))}
						</tbody>
					)}
				</table>
			</div>
		);
	}

	function renderResidualBonus() {
		return (
			<>
				<div
					className="table-container"
					style={{
						display: "flex",
						flexDirection: "column"
					}}
				>
					{data?.data1 && (
						<div style={{ flexGrow: 1 }}>
							<table className="striped" style={{ width: "50%" }}>
								{renderHeaders(headers.headers1)}

								{data?.data1?.length > 0 && (
									<tbody>
									{data?.data1?.map((row, rowIndex) => (
										<tr key={rowIndex}>
											<td>{numberDisplay(row.previousCarryoverLV)}</td>
											<td>{numberDisplay(row.periodLV)}</td>
											<td>{numberDisplay(row.currentLV)}</td>
											<td>{numberDisplay(row.previousCarryoverRV)}</td>
											<td>{numberDisplay(row.periodRV)}</td>
											<td>{numberDisplay(row.currentRV)}</td>
										</tr>
									))}
									</tbody>
								)}
							</table>
						</div>
					)}

					{data?.data2 && (
						<div style={{ flexGrow: 2 }}>
							<table className="striped" style={{ width: "100%" }}>
								{renderHeaders(headers.headers2)}

								{renderSkeletonOrEmpty(headers.headers2)}

								{data?.data2.length > 0 && (
									<tbody>
									{data?.data2.map((row, rowIndex) => (
										<tr key={rowIndex}>
											<td>{row.rank}</td>

											<td>
												<div className="profile_table">
													{row.commissionId > 0 ? (
														<img src="/images/icons-money-bag.png" alt="" />
													) : null}
												</div>
												{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.bonusAmount, conversionFactor, 0, false)}`}
											</td>
											<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.cumulativeBonusAmount, conversionFactor, 0, false)}`}</td>
											<td>{numberDisplay(row.totalLV)}</td>
											<td>{numberDisplay(row.cycleAmountLeftLeg)}</td>
											<td>{numberDisplay(row.currentLV)}</td>
											<td>{numberDisplay(row.totalRV)}</td>
											<td>{numberDisplay(row.cycleAmountRightLeg)}</td>
											<td>{numberDisplay(row.currentRV)}</td>
										</tr>
									))}
									</tbody>
								)}
							</table>
						</div>
					)}

					{data?.data3 && (
						<div style={{ flexGrow: 1 }}>
							<table className="striped" style={{ width: "50%" }}>
								{renderHeaders(headers.headers3)}

								{((data?.data3?.length ?? 0) === 0) && (
									<tbody>
									<tr>
										<td colSpan="7">
											{t("bonus.noFlushingThisPeriod")}
										</td>
									</tr>
									</tbody>
								)}

								{data?.data3.length > 0 && (
									<tbody>
									{data?.data3.map((row, rowIndex) => (
										<tr key={rowIndex}>
											<td>{numberDisplay(row.rank)}</td>
											<td>{numberDisplay(row.totalLV)}</td>
											<td>{numberDisplay(row.flushedLV)}</td>
											<td>{numberDisplay(row.carryoverLV)}</td>
											<td>{numberDisplay(row.totalRV)}</td>
											<td>{numberDisplay(row.flushedRV)}</td>
											<td>{numberDisplay(row.carryoverRV)}</td>
										</tr>
									))}
									</tbody>
								)}
							</table>
						</div>
					)}
				</div>
			</>
		);
	}

	function renderMatchingBonus() {
		return (
			<div className="table-container">
				<table className="striped" style={{ width: "100%" }}>
					{renderHeaders(headers)}

					{renderSkeletonOrEmpty(headers)}

					{data?.length > 0 && (
						<tbody>
						{data?.map((row, rowIndex) => (
							<tr key={rowIndex}>
								<td>{row.payOnRepId}</td>
								<td>{row.payOnName}</td>
								<td>{row.level}</td>
								<td>{row.compressedLevel}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.sourceAmount, conversionFactor, 0, false)}`}</td>
								<td>{`${row.bonusPercent} %`}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.amount, conversionFactor, 0, false)}`}</td>
							</tr>
						))}
						</tbody>
					)}
				</table>
			</div>
		);
	}

	function renderTotalBonus() {
		return (
			<div className="table-container">
				<table className="striped" style={{ width: "100%" }}>
					{renderHeaders(headers)}

					{renderSkeletonOrEmpty(headers)}

					{data?.length > 0 && (
						<tbody>
						{data?.map((row, rowIndex) => (
							<tr key={rowIndex}>
								<td>{`${t(getPeriodTypeLocaleKey(row.periodTypeId))}: ${row.period}`}</td>
								<td>
									<div className="profile_table">
										{row.isCommitted ? (
											<img src="/images/icons-check-mark.png" alt=""/>
										) : null}
									</div>
								</td>
								<td>{row.rank}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.totalAmount, conversionFactor, 0, false)}`}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.fastStart, conversionFactor, 0, false)}`}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.residual, conversionFactor, 0, false)}`}</td>
								<td>{`${userSelectedCurrency?.symbolLeft} ${CurrencyConverter(row.matching, conversionFactor, 0, false)}`}</td>
							</tr>
						))}
						</tbody>
					)}
				</table>
			</div>
		);
	}

	function renderQualifications() {
		return (
			<div className="table-container">
				<table className="striped" style={{width: "100%" }}>
					{renderHeaders(headers)}

					{renderSkeletonOrEmpty(headers)}

					{data?.length > 0 && (
						<tbody>
						{data?.map((row, rowIndex) => (
							<tr key={rowIndex}>
								<td>{`${t(getPeriodTypeLocaleKey(row.periodTypeId))}: ${row.period}`}</td>
								<td>{row.rank}</td>
								<td>
									<div className="profile_table">
										{row.isActive ? (
											<img src="/images/icons-check-mark.png" alt="" />
										) : null}
									</div>
								</td>
								<td>{numberDisplay(row.periodLV)}</td>
								<td>{numberDisplay(row.periodRV)}</td>
								<td>{row.activeRepsLeft}</td>
								<td>{row.activeRepsRight}</td>
							</tr>
							))}
						</tbody>
					)}
				</table>
			</div>
		);
	}

	function renderHeaders(headers) {
		return (
			<thead>
			<tr>
				{headers?.map((header, index) => {
					return (
						<Fragment key={index}>
							<th style={{ paddingRight: "5px" }}>{t(header)}</th>
						</Fragment>
					);
				})}
			</tr>
			</thead>
		);
	}

	function renderSkeletonOrEmpty(headers) {
		return (
			<>
				{!data ? (
					<tbody>
					<TableSkeleton rowCount={6} cellCount={headers?.length ?? 0} />
					</tbody>
				) : data?.length === 0 && (
					<tbody>
					<tr>
						<td colSpan={headers?.length ?? 0}>
							<div className="nodata-table-view">
								<div className="nodata-table-view-box">
									<div className="nodata-table-view-box-img">
										<img src="/images/no-data-image1.jpg" alt="" />
									</div>
									<div className="nodata-table-view-box-txt">
										{t("sorry_no_data_found")}
									</div>
								</div>
							</div>
						</td>
					</tr>
					</tbody>
				)}
			</>
		);
	}

	return (
		<>
			{
				reportTypeId === 1 ? renderFastStartBonus() :
				reportTypeId === 2 ? renderResidualBonus() :
				reportTypeId === 3 ? renderMatchingBonus() :
				reportTypeId === 100 ? renderTotalBonus() :
				reportTypeId === 101 ? renderQualifications() : null
			}
		</>
	);
};

export default CommissionTableContent;