import backToOffice from "../../services/auth/backToOffice";
import LoginService from "../../services/auth/Login";

export const StringValidate = async (string, code) => {
    try {
        return await backToOffice.accessToken(string, code);
    } catch (error) {
        console.log(error);
    }
}

export const ForgotPassword = async (data) => {
    try {
        return await LoginService.forgotPassword(data);
    } catch (error) {
        console.log(error);
    }
}

export const VerifyForgotPassword = async (data) => {
    try {
        return await LoginService.verifyForgotPassword(data);
    } catch (error) {
        console.log(error);
    }
}

export const ChangeForgotPassword = async (data) => {
    try {
        return await LoginService.changeForgotPassword(data);
    } catch (error) {
        console.log(error.message);
    }
}