import React from "react";
import TablePagination from "./TablePagination";
import CommissionTableContent from "./CommissionTableContent";

const CommissionTableMain = ({
	data,
	reportType,
	startPage,
	currentPage,
	totalPages,
	setCurrentPage,
	itemsPerPage,
	setItemsPerPage
}) => {
	const toNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		}
	};

	const toLastPage = () => {
		setCurrentPage(totalPages);
	};

	const toPreviousPage = () => {
		if (currentPage > startPage) {
			setCurrentPage(currentPage - 1);
		}
	};

	const toFirstPage = () => {
		setCurrentPage(startPage);
	};

	const handleItemsPerPageChange = (event) => {
		const selectedValue = parseInt(event.target.value);
		setItemsPerPage(selectedValue);
		setCurrentPage(1);
	};
	return (
		<>
			<CommissionTableContent
				data={data}
				reportType={reportType}
			/>
			{data && data?.length !== 0 && totalPages !== 0 && (
				<TablePagination
					startPage={startPage}
					currentPage={currentPage}
					totalPages={totalPages}
					setCurrentPage={setCurrentPage}
					itemsPerPage={itemsPerPage}
					setItemsPerPage={setItemsPerPage}
					toNextPage={toNextPage}
					toLastPage={toLastPage}
					toPreviousPage={toPreviousPage}
					toFirstPage={toFirstPage}
					handleItemsPerPageChange={handleItemsPerPageChange}
				/>
			)}
		</>
	);
};

export default CommissionTableMain;
