import { t } from "i18next";

export function getReportTypes() {
	const reportTypes = [
		{
			reportTypeId: 101,
			periodTypeId: 0,
			translationKey: "bonus.qualifications"
		},
		{
			reportTypeId: 100,
			periodTypeId: 0,
			translationKey: "bonus.totalBonus"
		},
		{
			reportTypeId: 1,
			periodTypeId: 2,
			translationKey: "bonus.fastStartBonus"
		},
		{
			reportTypeId: 2,
			periodTypeId: 2,
			translationKey: "bonus.residualBonus"
		},
		{
			reportTypeId: 3,
			periodTypeId: 1,
			translationKey: "bonus.matchingBonus"
		}
	];

	return reportTypes;
}

export function createReportTypeSelectOption(reportType) {
	let prefix = getPeriodTypeLocaleKey(reportType.periodTypeId);

	const label = `${t(reportType.translationKey)}` + (prefix !== "" ? ` (${t(prefix)})` : "");

	const option = {
		label: label,
		value: reportType.reportTypeId
	};

	return option;
}

export function createBonusProcessSelectOption(bonusProcess) {
	let prefix = getPeriodTypeLocaleKey(bonusProcess.periodTypeId);
	if (prefix === "") {
		prefix = "period";
	}

	const option = {
		label: `${t(prefix)}: ${bonusProcess.description}`,
		value: bonusProcess.processId
	};

	return option;
}

export function getPeriodTypeLocaleKey(periodTypeId) {
	let periodType = "";
	if (periodTypeId === 1) {
		periodType = "monthly";
	}
	else if (periodTypeId === 2) {
		periodType = "weekly";
	}
	else if (periodTypeId === 4) {
		periodType = "quarterly";
	}

	return periodType;
}

export function getInvoiceNumber(orderId) {
	return `INV${String(orderId ?? 0).padStart(6, '0')}`;
}