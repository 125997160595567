import React, { useState } from "react";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DatePickerComponent from "../Common/DatePickerComponent";
import dayjs from "dayjs";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { PhoneInput } from "react-international-phone";
import { useForm } from "react-hook-form";

const AddLeadForm = () => {
    const { t } = useTranslation();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const {
        register,
        setValue,
        setError,
        trigger,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        firstName: "",
        lastName: "",
        emailId: "",
        skypeId: "",
        mobileNo: "",
        countryId: "",
        interestStatus: "",
        followupDate: "",
        leadStatus: "",
        description: "",
    });
    const formValues = watch();
    const [phoneNumber, setPhoneNumber] = useState("");
    const [country, setSelectedCountry] = useState("");
    const [interestStatus, setInterestStatus] = useState("");
    const [followupDate, setFollowupDate] = useState();
    const [leadStatus, setLeadStatus] = useState();
    const [loadingStatus, setLoadingStatus] = useState(false);
    const initialFormData = {
        firstName: "",
        lastName: "",
        emailId: "",
        skypeId: "",
        mobileNo: "",
        countryId: "",
        interestStatus: "",
        followupDate: "",
        leadStatus: "",
        description: "",
    };

    const [errorMessage, setErrorMessage] = useState({
        firstName: null,
        lastName: null,
        emailId: null,
        skypeId: null,
        mobileNo: null,
        countryId: null,
        interestStatus: null,
        followupDate: null,
        leadStatus: null,
        description: null,
    });
    // const [formData, setFormData] = useState(initialFormData);
    const [isCalenderOpen, setIsCalenderOpen] = useState({
        nextDate: false,
    });

    const interestOptions = [
        {
            label: t("not_that_interested"),
            value: 0,
        },
        {
            label: t("interested"),
            value: 1,
        },
        {
            label: t("very_interested"),
            value: 2,
        },
    ];

    const statusOptions = [
        {
            label: t("ongoing"),
            value: 1,
        },
        {
            label: t("accepted"),
            value: 2,
        },
        {
            label: t("rejected"),
            value: 0,
        },
    ];

    //------------------------------------------- API --------------------------------------------------

    const addLeadMutation = ApiHook.CallAddCrmLead();
    const countries = ApiHook.CallGetCountries();

    const isPhoneValid = (phone) => {
        return phone.length >= 7 && !isNaN(phone);
    };
    const isPhoneNumberValid = isPhoneValid(phoneNumber);

    const handlePhoneNumber = (phone) => {
        setPhoneNumber(phone);
        setValue("mobileNo", phone);
        setError("mobileNo", { message: "" });
    };

    const countryChange = (country) => {
        setSelectedCountry(country);
        setValue("countryId", country?.value);
        setError("countryId", { message: "" });
    };

    const changeInterestLevel = (level) => {
        setInterestStatus(level);
        setValue("interestStatus", level?.value);
        setError("interestStatus", { message: "" });
    };

    const handleDateChange = (newDate) => {
        if (newDate) {
            setFollowupDate(newDate);
            const formattedDate = newDate.format("YYYY-MM-DD");
            setValue("followupDate", formattedDate);
            setError("followupDate", { message: "" });
        }
    };
    const changeLeadStatus = (status) => {
        setLeadStatus(status);
        setValue("leadStatus", status?.value);
        setError("leadStatus", { message: "" });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingStatus(true);
        let isValid = await trigger();
        if (!isPhoneNumberValid) {
            isValid = false;
            setError("mobileNo", {
                message: ("min_length"),
            });
        }
        const today = dayjs(); // Get today's date

        // Check if next follow-up date is in the past, today or null
        if (formValues.followupDate === undefined) {
            setError("followupDate", { message: "this_field_is_required" });
            return; // Stop further execution
        } else if (dayjs(formValues.followupDate).isBefore(today, 'day') || dayjs(formValues.followupDate).isSame(today, 'day')) {
            // Display error message
            toast.error(t("Next follow-up date cannot be today or in the past"));
            return; // Stop further execution
        }
        const hasErrors =
            errorMessage.firstName ||
            errorMessage.emailId ||
            errorMessage.description;
        if (isValid) {
            addLeadMutation.mutate(formValues, {
                onSuccess: (res) => {
                    if (res.status) {
                        toast.success(res.data);
                        reset();
                        setSelectedDate("");
                        setInterestStatus("");
                        setPhoneNumber("");
                        setFollowupDate(null);
                        setSelectedCountry("");
                    } else {
                        toast.error(res?.message);
                    }
                },
            });
        }
        setLoadingStatus(false);
    };
    return (
        <>
            <div className="page_head_top">{t("add_lead")}</div>
            <div className="frm-content">
                <div className="p-3 container-fluid">
                    <div className="crm_panel__h6izZ">
                        <div className="crm_panel_body__31wq1">
                            <form className="" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-xl-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("firstName")}
                                                <span className="text-danger">٭</span>
                                            </label>
                                            <input
                                                {...register("firstName", {
                                                    required: {
                                                        value: true,
                                                        message: (
                                                            "this_field_is_required"
                                                        ),
                                                    },
                                                    pattern: {
                                                        value: /^[A-Za-z]+$/,
                                                        message: "only_alphabets_allowed",
                                                    },
                                                })}
                                                id="firstName"
                                                name="firstName"
                                                type="text"
                                                className="form-control"
                                                onBlur={async () =>
                                                    await trigger("firstName")
                                                }
                                            />
                                            {errors.firstName && (
                                                <span className="error-message-validator">
                                                    {t(errors.firstName.message)}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("lastName")}
                                            </label>
                                            <input
                                                {...register("lastName")}
                                                name="lastName"
                                                type="text"
                                                className="form-control"
                                                onBlur={async () =>
                                                    await trigger("lastName")
                                                }
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("email")}
                                                <span className="text-danger">٭</span>
                                            </label>
                                            <input
                                                {...register("emailId", {
                                                    required: {
                                                        value: true,
                                                        message: (
                                                            "this_field_is_required"
                                                        ),
                                                    },
                                                    pattern: {
                                                        value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]+$/,
                                                        message: t(
                                                            "invalid_email_format"
                                                        ),
                                                    },
                                                })}
                                                name="emailId"
                                                type="text"
                                                className="form-control"
                                                onBlur={async () =>
                                                    await trigger("emailId")
                                                }
                                            />
                                            {errors.emailId && (
                                                <span className="error-message-validator">
                                                    {t(errors.emailId.message)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("skype")}
                                            </label>
                                            <input
                                                {...register("skypeId")}
                                                name="skypeId"
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("mobile")}
                                                <span className="text-danger">٭</span>
                                            </label>
                                            <PhoneInput
                                                className="phone-input"
                                                defaultCountry="us"
                                                value={phoneNumber}
                                                onChange={handlePhoneNumber}
                                            />
                                            {errors.mobileNo && (
                                                <span className="error-message-validator">
                                                    {t(errors.mobileNo.message)}
                                                </span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="countryId"
                                                className="form-label"
                                            >
                                                {t("country")}
                                            </label>
                                            <Select
                                                id={"countryId"}
                                                name={"countryId"}
                                                className={`dropdown-common-addLead ${errors["countryId"]
                                                    ? "error-field"
                                                    : ""
                                                    }`}
                                                {...register("countryId")}
                                                onBlur={async () =>
                                                    await trigger("countryId")
                                                }
                                                options={countries?.data}
                                                onChange={countryChange}
                                                value={country}
                                                isSearchable
                                            />
                                        </div>
                                    </div>
                                    <div className="col-xl-4">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("level_of_interest")}
                                            </label>
                                            <Select
                                                id={"interestStatus"}
                                                name={"interestStatus"}
                                                className={`dropdown-common-addLead`}
                                                {...register("interestStatus")}
                                                options={interestOptions}
                                                onChange={changeInterestLevel}
                                                value={interestStatus}
                                                isSearchable={false}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("followup_date")}
                                                <span className="text-danger">٭</span>
                                            </label>
                                            <DatePickerComponent
                                                className={"date-picker-addlead"}
                                                date={followupDate}
                                                handleDateChange={(newDate) =>
                                                    handleDateChange(newDate)
                                                }
                                                isCalenderOpen={
                                                    isCalenderOpen.nextDate
                                                }
                                                openCalender={() =>
                                                    setIsCalenderOpen({
                                                        ...isCalenderOpen,
                                                        nextDate: true,
                                                    })
                                                }
                                                closeCalender={() =>
                                                    setIsCalenderOpen({
                                                        ...isCalenderOpen,
                                                        nextDate: false,
                                                    })
                                                }
                                                past={true}
                                            />
                                            {errors.followupDate && (
                                                <span className="error-message-validator">
                                                    {t(errors.followupDate.message)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-xl-12">
                                        <div className="form-group">
                                            <label className="form-label">
                                                {t("description")}
                                                <span className="text-danger">٭</span>
                                            </label>
                                            <textarea
                                                {...register("description", {
                                                    required: "this_field_is_required"
                                                })}
                                                name="description"
                                                type="text"
                                                className="form-control"
                                            />
                                            {errors.description && (
                                                <span className="error-message-validator">
                                                    {t(errors.description.message)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    className="Common_customBtn__2_PSp Common_primary__2pdY1 undefined btn btn-primary"
                                >
                                    {t("add_lead")}
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddLeadForm;
